<template>
  <div class="bg-primary h-[4px] relative">
    <svg
      class="w-49 lg:w-[201px] h-4 lg:h-8 absolute right-0 -bottom-3 lg:-bottom-8"
      width="201"
      height="8"
      viewBox="0 0 201 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.7219 8H201V0H0L15.7219 8Z" fill="#51E2FF" />
    </svg>
  </div>
</template>
